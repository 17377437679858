import { createContext, useContext } from "react";

import localForage from "localforage";
import {
  Instance,
  SnapshotIn,
  applySnapshot,
  getSnapshot,
  types,
} from "mobx-state-tree";
import { IOptions, persist } from "mst-persist";

import { List } from "~/components/Reports/ReportModels";
import { SelectOption } from "~/components/ui/select";
import { defaultOverrides } from "~/hooks/useOverrides";

const STORAGE_KEY = "root";

export enum Environment {
  PRODUCTION = "Production",
  STAGING = "Staging",
  DEV = "Dev",
  LOCAL = "Local-Dev",
}

export enum override_key {
  PARTNER = "Partner_Label",
  PARTNERS = "Partners_Label",
  PROGRAM = "Program_Label",
  PROGRAMS = "Programs_Label",
  COURSE = "Course_Label",
  COURSES = "Courses_Label",
  MODULE = "Module_Label",
  MODULES = "Modules_Label",
  ACTIVITY = "Activity_Label",
  ACTIVITIES = "Activities_Label",
}

//shape of the permissions that comes back from the database
const Permission = types.model("Permission", {
  id: types.integer,
  created_at: types.integer,
  app_users_id: types.integer,
  app_role_id: types.integer,
  app_permission_id: types.integer,
  permission_token: types.string,
  scope: types.string,
  agency_id: types.integer,
  partner_id: types.integer,
  program_id: types.integer,
  created_by_id: types.integer,
});
export type PermissionInstance = Instance<typeof Permission>;
export type IPermission = SnapshotIn<typeof Permission>;

const Overrides = types.model("Overrides", {
  id: types.number,
  key: types.string,
  value: types.string,
  type: types.string,
  is_override: types.boolean,
});

export type IOverridesInstance = Instance<typeof Overrides>;
export type IOverrides = SnapshotIn<typeof Overrides>;

//shape of the overrides that comes back from the database
const OverridesItem = types.model("OverridesItem", {
  id: types.number,
  key: types.string,
  value: types.string,
  type: types.string,
  is_override: types.boolean,
});

export type IOverridesItemInstance = Instance<typeof OverridesItem>;
export type IOverridesItem = SnapshotIn<typeof OverridesItem>;

const AppUserModel = types.model("AppUser", {
  authtoken: types.optional(types.string, ""),
  first_name: types.string,
  last_name: types.string,
  name: types.string,
  user_type: types.optional(types.string, ""),
  agency_id: types.optional(types.number, 0),
  agency_name: types.optional(types.string, ""),
  partner_id: types.number,
  partner_name: types.optional(types.string, ""),
  basic_permissions: types.optional(types.array(types.string), []),
  user_permissions: types.optional(types.array(Permission), []),
  id: types.string,
  email: types.string,
  password: types.optional(types.string, ""),
  account_status: types.optional(types.string, ""),
  title: types.optional(types.string, ""),
  selected_partner_id: types.optional(types.number, 0),
  selected_program_id: types.optional(types.number, 0),
  overrides: types.optional(types.array(Overrides), defaultOverrides),
  created_at: types.optional(types.number, 0),
  last_login: types.maybeNull(types.number),
  password_expires_at: types.maybeNull(types.number),
  updated_at: types.maybeNull(types.number),
  move: types.optional(types.boolean, false),
  delete: types.optional(types.boolean, false),
});

export type IAppUserInstance = Instance<typeof AppUserModel>;
export type IAppUser = SnapshotIn<typeof AppUserModel>;

const initAppUser = getSnapshot(
  AppUserModel.create({
    authtoken: "",
    first_name: "",
    last_name: "",
    name: "",
    user_type: "",
    agency_id: 0,
    agency_name: "",
    partner_id: 0,
    partner_name: "",
    basic_permissions: [],
    user_permissions: [],
    id: "",
    email: "",
    password: "",
    account_status: "",
    title: "",
    selected_partner_id: 0,
    selected_program_id: 0,
    overrides: defaultOverrides,
    created_at: 0,
    last_login: 0,
    password_expires_at: 0,
    updated_at: 0,
  }),
);

export const RootModel = types
  .model("Root", {
    authToken: types.optional(types.string, ""),
    appUser: types.optional(AppUserModel, initAppUser),
    isLoggedIn: types.optional(types.boolean, false),
    dropdownListReports: types.optional(
      types.array(types.frozen<SelectOption>()),
      [],
    ),
    targetListReports: types.maybeNull(types.frozen<List>()),
    sessionExpirationDialog: types.optional(types.boolean, false),
    sessionExpirationTimer: types.maybeNull(types.number),
    finalSessionExpirationTimer: types.maybeNull(types.number),
  })
  .views((self) => ({
    getAuthToken() {
      return self.authToken;
    },
    getAppUser() {
      return self.appUser;
    },
    get selectedProgramId() {
      return self.appUser.selected_program_id;
    },
    get selectedPartnerId() {
      return self.appUser.selected_partner_id;
    },
    getLoggedIn() {
      return self.isLoggedIn;
    },
    getEnvironment() {
      const currentUrl = window.location.href;
      if (currentUrl.includes("localhost")) {
        return Environment.LOCAL;
      } else if (currentUrl.includes("studio-dev")) {
        return Environment.DEV;
      } else if (currentUrl.includes("studio-staging")) {
        return Environment.STAGING;
      } else if (currentUrl.includes("studio-app")) {
        return Environment.PRODUCTION;
      } else {
        return Environment.DEV;
      }
    },
    getOverrides(key: override_key) {
      return self.appUser.overrides.find((override) => override.key === key)
        ?.value;
    },
    getTargetListReports() {
      return self.targetListReports;
    },
    getDropdownListReports() {
      return self.dropdownListReports;
    },
    getSessionExpirationDialog() {
      return self.sessionExpirationDialog;
    },
    getSessionExpirationTimer() {
      return self.sessionExpirationTimer;
    },
    getFinalSessionExpirationTimer() {
      return self.finalSessionExpirationTimer;
    }
  }))
  .actions((self) => ({
    setAuthToken(value: string) {
      self.authToken = value;
      //console.log('setting AuthToken', value)
    },
    resetState() {
      const defaultSnapshot = getSnapshot(RootModel.create());
      //console.log('resetting state', defaultSnapshot)
      applySnapshot(self, defaultSnapshot);
      return defaultSnapshot;
    },
    setAppUser(appUser: IAppUser) {
      applySnapshot(self.appUser, appUser);
    },
    resetAppUser() {
      applySnapshot(self.appUser, initAppUser);
    },
    setSelectedProgramId(value: number) {
      self.appUser.selected_program_id = value;
    },
    resetSelectedProgramId() {
      self.appUser.selected_program_id = 0;
    },
    setSelectedPartnerId(value: number) {
      self.appUser.selected_partner_id = value;
    },
    resetSelectedPartnerId() {
      self.appUser.selected_program_id = 0;
    },
    setOverrides(newOverrides: IOverrides[]) {
      applySnapshot(self.appUser.overrides, []);
      applySnapshot(self.appUser.overrides, newOverrides);
    },
    setLoggedIn(value: boolean) {
      self.isLoggedIn = value;
    },
    setTargetListReports(value: List) {
      self.targetListReports = value;
    },
    resetTargetListReports() {
      self.targetListReports = null;
    },
    setDropdownListReports(value: SelectOption[]) {
      applySnapshot(self.dropdownListReports, []);
      applySnapshot(self.dropdownListReports, value);
    },
    setSessionExpirationDialog(value: boolean) {
      self.sessionExpirationDialog = value;
    },
    setSessionExpirationTimer(value: number | null) {
      self.sessionExpirationTimer = value;
    },
    setFinalSessionExpirationTimer(value: number | null) {
      self.finalSessionExpirationTimer = value;
    }
  }));

export const rootStore = RootModel.create();

export type RootInstance = Instance<typeof RootModel>;

const RootStoreContext = createContext<RootInstance>({} as RootInstance);

export const Provider = RootStoreContext.Provider;

export const useStore = () => {
  const store = useContext(RootStoreContext);
  if (store === null) {
    throw new Error("Store cannot be null, please add a context provider");
  }
  return store as RootInstance;
};

export type MapStore<T> = (store: RootInstance) => T;

export const useInject = <T>(mapStore: MapStore<T>) => {
  const store = useStore();
  return mapStore(store);
};

export const initPersistence = async (): Promise<RootInstance> => {
  // state persist, rehydrate
  const options: IOptions = {
    storage: localForage,
    jsonify: true,
  };
  try {
    await persist(STORAGE_KEY, rootStore, options);
    console.log(" --> state rehydrated!");
  } catch (err) {
    // console.warn('ERROR rehydrating state', err, rootStore)
    const snapshot = rootStore.resetState();
    await localForage.setItem(STORAGE_KEY, JSON.stringify(snapshot));
  }
  return rootStore;
};
